@tailwind base;
@tailwind components;
@tailwind utilities;

input:checked + div {
  @apply border-white bg-transparent;
}
input:checked + div svg {
  @apply block;
}

label.error {
  @apply relative bottom-5 left-0 max-w-[280px] text-white text-xs
}

@font-face {
  font-family: "InterRegular";
  src: url("/static/fonts/Inter-Regular.ttf");
}
@font-face {
  font-family: "InterMedium";
  src: url("/static/fonts/Inter-Medium.ttf");
}
@font-face {
  font-family: "TacticSansExdReg";
  src: url("/static/fonts/TacticSansExd-Reg.otf");
}

body {
  @apply font-inter
}

.swiper-view.swiper-button-next, .swiper-view.swiper-button-prev {
  @apply bg-slate-200 rounded-full !w-16 !h-16 !top-[20%] -translate-y-1/2 md:!top-[25%]
}

.swiper-review .swiper-button-next, .swiper-review .swiper-button-prev {
  @apply bg-slate-200 rounded-full !w-16 !h-16 !top-[20%] -translate-y-1/2 md:!top-[35%]}

.ac-trigger::after {
  @apply text-skyblue;
  content: ''!important;
    border-right: 2px;
    border-bottom: 2px;
    border-color: skyblue;
    width: 12px!important;
    border-right-style: solid;
    border-bottom-style: solid;
    height: 12px!important;
    position: relative;
    z-index: 124;
    transform: rotate(45deg)!important;
}
.ac.is-active>.ac-header .ac-trigger::after {
   content: ''!important;

    transform: rotate(225deg)!important;
}

.ac .ac-trigger::after {
  @apply !top-1/2 !-translate-y-1/2 !rotate-45
}